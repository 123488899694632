import React, { useState } from 'react';

interface MuzedProps {
  onClose: () => void;
}

const Muzed: React.FC<MuzedProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Muzed</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2024</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status works">In the Works</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">App</span>
                </div>
                <div className="overview-item">
                  <span className="label">Platform</span>
                  <span className="value">Web & (iOS & Android planned)</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>Muzed brings art to life through storytelling. Using the latst AI vision technology, the app instantly recognizes artwork around you - from paintings and sculptures to murals and installations. Once identified, Muzed delivers immersive audio narratives and rich multimedia stories that reveal the fascinating histories, creative processes, and cultural significance behind each piece. Users can both listen to content curated by others and contribute their own perspectives, creating a growing collection of compelling audio guides that make art more accessible and engaging for everyone.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>Art has stories that deserve to be told and heard. Every piece has a unique history, context, and meaning that often goes undiscovered. Muzed aims to bridge this gap by creating an accessible platform where artists, curators, and art enthusiasts can share these stories, making art even more engaging and meaningful for everyone (especially those who may not typically enjoy the traditional museum experience).</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <p>Coming Soon!</p>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://muzed.app" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-globe"></i>
                  Web App (Beta)
                </a>
                <a href="#" className="resource-link" onClick={(e) => e.preventDefault()}>
                  <i className="bi bi-phone"></i>
                  Coming Soon to App Store
                </a>
                <a href="#" className="resource-link" onClick={(e) => e.preventDefault()}>
                  <i className="bi bi-phone"></i>
                  Coming Soon to Play Store
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/muzed/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/muzed/gallery${num}.jpg`}
                      alt={`Muzed Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default Muzed; 