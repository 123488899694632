import React, { useState, useEffect } from 'react';
import useActiveSection from '../hooks/useActiveSection';
import ContactAdventure from './ContactAdventure';

interface HeaderProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  onProjectClick: (projectId: string) => void;
  activeProjects: Array<{ id: string, title: string, description: string }>;
  showContactAdventure: boolean;
  onStartAdventure: () => void;
  onCloseAdventure: () => void;
}

interface ActivityInfo {
  [key: string]: string;
}

const Header: React.FC<HeaderProps> = ({ isMenuOpen, toggleMenu, onProjectClick, activeProjects, showContactAdventure, onStartAdventure, onCloseAdventure }) => {
  const activeSection = useActiveSection();
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [expandedSection, setExpandedSection] = useState<'work' | 'favorites' | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [expandedActivity, setExpandedActivity] = useState<string | null>(null);

  useEffect(() => {
    const checkScreenHeight = () => {
      const isSmall = window.innerHeight <= 800;
      setIsSmallScreen(isSmall);
      if (isSmall) {
        setExpandedSection(null);
      }
    };

    checkScreenHeight();
    window.addEventListener('resize', checkScreenHeight);
    return () => window.removeEventListener('resize', checkScreenHeight);
  }, []);

  const activityInfo: ActivityInfo = {
    biking: "Exploring Denver's hundreds of miles of trails and commuting by bike whenever possible.",
    hiking: "Summitting Colorado's 14ers in sometimes unique and crazy ways.",
    rafting: "Packrafting and boating with friends into the depths of Colorado.",
    snowboarding: "Chasing powder in the Colorado Rockies and always looking for new lines to ride.",
    travel: "Exploring new places, meeting new people, and collecting stories from around the world.",
    music: "Seeking out live shows and discovering new artists across different genres.",
    coffee: "Drinking PSLs and not being ashamed to admit it. Sometimes making my own syrups at home.",
    cocktails: "Experimenting with new twists on classic cocktails (current fav: Naked & Famous)"
  };

  const toggleSection = (section: 'work' | 'favorites') => {
    if (isSmallScreen) {
      setExpandedSection(expandedSection === section ? null : section);
    }
  };

  const toggleActivity = (activity: string) => {
    setExpandedActivity(expandedActivity === activity ? null : activity);
  };

  return (
    <>
      <button 
        className="mobile-nav-toggle" 
        onClick={toggleMenu}
        aria-label="Toggle Navigation"
      >
        <i className={`bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`}></i>
      </button>

      <header id="header" className={isMenuOpen ? 'mobile-nav-active' : ''}>
        <div className="profile">
          <div className="profile-image-container">
            <img src="/img/me.jpg" alt="" />
            <div className="profile-tooltip">Hanging out in Honduras!</div>
          </div>
          <h1><a href="#hero">Josh Manning</a></h1>
          <div className="social-links">
            <button 
              onClick={() => onStartAdventure()}
              className="adventure-link"
            >
              <i className="bi bi-compass"></i>
            </button>
            <a href="https://www.linkedin.com/in/joshmanning/" target="_blank" rel="noopener noreferrer" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
            <a href="https://www.facebook.com/joshjmanning" target="_blank" rel="noopener noreferrer" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/manning_space/" target="_blank" rel="noopener noreferrer" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
          </div>
        </div>

        <nav className="nav-menu">
          <ul>
            <li><a href="#hero" className={activeSection === 'hero' ? 'active' : ''}><i className="bi bi-house"></i> Home</a></li>
            <li><a href="#about" className={activeSection === 'about' ? 'active' : ''}><i className="bi bi-person"></i> About</a></li>
            <li><a href="#portfolio" className={activeSection === 'portfolio' ? 'active' : ''}><i className="bi bi-lightbulb"></i> Projects</a></li>
          </ul>
        </nav>

        <div className="menu-footer">
          <div className="collapsible-section">
            <h3 onClick={() => toggleSection('work')} className="section-header">
              Currently Working On
              {isSmallScreen && (
                <i className={`bi bi-chevron-${expandedSection === 'work' ? 'up' : 'down'}`}></i>
              )}
            </h3>
            <div className={`section-content ${(!isSmallScreen || expandedSection === 'work') ? 'expanded' : ''}`}>
              <div className="work-links">
                <button 
                  onClick={() => onProjectClick('muzed')}
                  className="work-link"
                >
                  Muzed
                  <p className="work-description">Art discovery through storytelling</p>
                </button>
                <button 
                  onClick={() => onProjectClick('gatherly')}
                  className="work-link"
                >
                  Gatherly
                  <p className="work-description">Simple event organizing without accounts</p>
                </button>
                <button 
                  onClick={() => onProjectClick('rafteo')}
                  className="work-link"
                >
                  Rafteo
                  <p className="work-description">Innovative gear for river sports</p>
                </button>
                <button 
                  onClick={() => onProjectClick('bandbacon')}
                  className="work-link"
                >
                  BandBacon
                  <p className="work-description">Exploring musical connections</p>
                </button>
              </div>
            </div>
          </div>
          
          <div className="collapsible-section">
            <h3 onClick={() => toggleSection('favorites')} className="section-header">
              Some of My Favorite Things
              {isSmallScreen && (
                <i className={`bi bi-chevron-${expandedSection === 'favorites' ? 'up' : 'down'}`}></i>
              )}
            </h3>
            <div className={`section-content ${(!isSmallScreen || expandedSection === 'favorites') ? 'expanded' : ''}`}>
              <div className="favorite-activities">
                <div 
                  className={`activity-tag ${expandedActivity === 'biking' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('biking')}
                >
                  <div className="activity-content">
                    <i className="bi bi-bicycle"></i>
                    <span className="activity-label">Biking</span>
                    {expandedActivity === 'biking' && (
                      <span className="activity-description">{activityInfo.biking}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'hiking' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('hiking')}
                >
                  <div className="activity-content">
                    <i className="bi bi-compass"></i>
                    <span className="activity-label">Hiking</span>
                    {expandedActivity === 'hiking' && (
                      <span className="activity-description">{activityInfo.hiking}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'rafting' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('rafting')}
                >
                  <div className="activity-content">
                    <i className="bi bi-water"></i>
                    <span className="activity-label">Rafting</span>
                    {expandedActivity === 'rafting' && (
                      <span className="activity-description">{activityInfo.rafting}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'snowboarding' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('snowboarding')}
                >
                  <div className="activity-content">
                    <i className="bi bi-snow"></i>
                    <span className="activity-label">Snowboarding</span>
                    {expandedActivity === 'snowboarding' && (
                      <span className="activity-description">{activityInfo.snowboarding}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'travel' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('travel')}
                >
                  <div className="activity-content">
                    <i className="bi bi-airplane"></i>
                    <span className="activity-label">Travel</span>
                    {expandedActivity === 'travel' && (
                      <span className="activity-description">{activityInfo.travel}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'music' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('music')}
                >
                  <div className="activity-content">
                    <i className="bi bi-music-note"></i>
                    <span className="activity-label">Music</span>
                    {expandedActivity === 'music' && (
                      <span className="activity-description">{activityInfo.music}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'coffee' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('coffee')}
                >
                  <div className="activity-content">
                    <i className="bi bi-cup-hot"></i>
                    <span className="activity-label">Coffee</span>
                    {expandedActivity === 'coffee' && (
                      <span className="activity-description">{activityInfo.coffee}</span>
                    )}
                  </div>
                </div>
                <div 
                  className={`activity-tag ${expandedActivity === 'cocktails' ? 'expanded' : ''}`}
                  onClick={() => toggleActivity('cocktails')}
                >
                  <div className="activity-content">
                    <i className="bi bi-cup"></i>
                    <span className="activity-label">Cocktails</span>
                    {expandedActivity === 'cocktails' && (
                      <span className="activity-description">{activityInfo.cocktails}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {showContactAdventure && (
        <ContactAdventure onClose={onCloseAdventure} />
      )}
    </>
  );
};

export default Header; 