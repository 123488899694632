import React, { useState } from 'react';

interface BandBaconProps {
  onClose: () => void;
}

const BandBacon: React.FC<BandBaconProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Band Bacon</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2024</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status works">In the Works</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">App</span>
                </div>
                <div className="overview-item">
                  <span className="label">Platform</span>
                  <span className="value">Web & (iOS & Android planned)</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>BandBacon is a platform that explores and visualizes the interconnections between musicians and bands, similar to the concept of "six degrees of separation." It maps out how artists are connected through their collaborations, band memberships, and musical influences, creating a fascinating web of musical relationships.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>In a world where music discovery is increasingly controlled by opaque algorithms, I wanted to create a more transparent and intentional way to find new music. By visualizing the relationships between artists - their side projects, collaborations, and musical journeys - users can naturally explore and discover music through explicit connections rather than black-box recommendations. The interactive visualization lets you browse through these relationships, revealing surprising connections between bands you love and helping you find new artists through a clear path of musical connections.</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <p>Coming Soon!</p>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://bandbacon.app/" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-globe"></i>
                  BandBacon Website
                </a>
                <a href="#" className="resource-link" onClick={(e) => e.preventDefault()}>
                  <i className="bi bi-phone"></i>
                  Coming Soon to App Store
                </a>
                <a href="#" className="resource-link" onClick={(e) => e.preventDefault()}>
                  <i className="bi bi-phone"></i>
                  Coming Soon to Play Store
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2, 3, 4].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/bandbacon/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/bandbacon/gallery${num}.jpg`}
                      alt={`BandBacon Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default BandBacon; 