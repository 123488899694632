import React from 'react';

interface FooterProps {
  onStartAdventure: () => void;
}

const Footer: React.FC<FooterProps> = ({ onStartAdventure }) => {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="copyright">
            <span>Made with ❤️ in Colorado • </span>
            <button onClick={onStartAdventure} className="nav-link">Get in touch!</button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 