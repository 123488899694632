import React, { useState, useEffect } from 'react';
import SLSP from './projects/SLSP';
import AHAH from './projects/AHAH';
import NNO from './projects/NNO';
import Muzed from './projects/Muzed';
import Gatherly from './projects/Gatherly';
import BandBacon from './projects/BandBacon';
import Rafteo from './projects/Rafteo';

type ProjectCategory = 'all' | 'ongoing' | 'works' | 'past' | 'ideas';

// Add toggleMenu to props
interface PortfolioProps {
  onStartAdventure: () => void;
  selectedProject: string | null;
  onCloseProject: () => void;
  onProjectClick: (projectId: string) => void;
}

const Portfolio: React.FC<PortfolioProps> = ({ onStartAdventure, selectedProject, onCloseProject, onProjectClick }) => {
  const [activeFilter, setActiveFilter] = useState<ProjectCategory>('all');

  const filterProjects = (category: ProjectCategory) => {
    setActiveFilter(category);
  };

  const projects = [
    // Ongoing Projects
    {
      id: 'slsp',
      title: "Sloan's Lake Search Party (SLSP)",
      description: "A loop-based Marathon around Sloan's Lake in Denver, Colorado in search for the mythical Goosifer. [Est. 2023]",
      image: "/img/portfolio/slsp.png",
      category: 'ongoing'
    },
    {
      id: 'nno',
      title: "Nerd Nite Orlando",
      description: "An evening of entertaining yet somehow still educational talks. [Est. 2013]",
      image: "/img/portfolio/nno.png",
      category: 'ongoing'
    },
    // In the Works Projects
    {
      id: 'gatherly',
      title: "Gatherly Org",
      description: "A very simple event organizing site that doesn't require login or credentials and integrates with most calendars.",
      image: "/img/portfolio/gatherly.png",
      category: 'works'
    },
    {
      id: 'rafteo',
      title: "Rafteo River Systems",
      description: "Unique technical gear for river sports that solve real problems.",
      image: "/img/portfolio/rafteo.png",
      category: 'works'
    },
    {
      id: 'muzed',
      title: "Muzed",
      description: "Immerse yourself in stories about the art that surrounds you. [Est. 2024]",
      image: "/img/portfolio/muzed.png",
      category: 'works'
    },
    {
      id: 'bandbacon',
      title: "BandBacon",
      description: "An exploration of direct musical relationships among bands and artists (think six degrees of separation)",
      image: "/img/portfolio/bandbacon.png",
      category: 'works'
    },
    // Past Projects
    {
      id: 'seeded',
      title: "Seeded Podcast",
      description: "A podcast of compelling stories of innovation that unearth the lessons of what it truly takes to grow something new.",
      image: "/img/portfolio/seeded.png",
      category: 'past'
    },
    {
      id: 'tgb',
      title: "TriGoodBeer Triathlon Team",
      description: "A triathlon team that trains and parties hard!",
      image: "/img/portfolio/tgb.png",
      category: 'past'
    },
    {
      id: 'pogonauts',
      title: "Pogonauts and Stiff Upper Lip",
      description: "The great explorers of the facial frontier and makers of unusual mustache waxes (fruity pebbles, coconut)!",
      image: "/img/portfolio/pogonauts.png",
      category: 'past'
    },
    {
      id: 'beaster',
      title: "Beaster",
      description: "An all-day \"Easter beer hunt\" and rare meat cookout event with a mythical storyline. [2014]",
      image: "/img/portfolio/beaster.png",
      category: 'past'
    },
    // Concept Projects
    {
      id: 'ahah',
      title: "A Horse A Half (AHAH)",
      description: "Unusual adventures including the annual BV3 to hike a 14er, bike from trailhead to river, and raft the Arkansas. [Est. 2020]",
      image: "/img/portfolio/ahah.png",
      category: 'ideas'
    },
    {
      id: 'speedfriending',
      title: "Speedfriending",
      description: "Speedfriending is exactly what it seems. It's the fast and friendly way to forge new friendships!",
      image: "/img/portfolio/speedfriending.png",
      category: 'ideas'
    },
    {
      id: 'goodies',
      title: "The Goodies",
      description: "A way to celebrate those who have a meaningful and positive impact on the community",
      image: "/img/portfolio/goodies.png",
      category: 'ideas'
    }
  ];

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container" data-aos="fade-up">
        <h2>Projects and Ideas</h2>
        <p>These are a few of the projects and things that have kept me busy over the years. Some I'm actively working on while others were just ideas never realized or fun one-and-done events.</p>
        
        <div className="portfolio-filters" data-aos="fade-up">
          <button 
            className={`filter-btn ${activeFilter === 'all' ? 'active' : ''}`}
            onClick={() => filterProjects('all')}
            data-filter="all"
          >
            All Projects
          </button>
          <button 
            className={`filter-btn ${activeFilter === 'ongoing' ? 'active' : ''}`}
            onClick={() => filterProjects('ongoing')}
            data-filter="ongoing"
          >
            Ongoing
          </button>
          <button 
            className={`filter-btn ${activeFilter === 'works' ? 'active' : ''}`}
            onClick={() => filterProjects('works')}
            data-filter="works"
          >
            In the Works
          </button>
          <button 
            className={`filter-btn ${activeFilter === 'past' ? 'active' : ''}`}
            onClick={() => filterProjects('past')}
            data-filter="past"
          >
            The Past
          </button>
          <button 
            className={`filter-btn ${activeFilter === 'ideas' ? 'active' : ''}`}
            onClick={() => filterProjects('ideas')}
            data-filter="ideas"
          >
            Concepts
          </button>
        </div>

        {activeFilter === 'ideas' && (
          <p className="concepts-subheading" data-aos="fade-up">
            Want to help bring these ideas to life? <button onClick={onStartAdventure} className="nav-link">Get in touch!</button>
          </p>
        )}

        <div className="portfolio-grid" data-aos="fade-up">
          {projects.map(project => {
            const statusLabel = project.category === 'works' ? 'In the Works' : 
                              project.category === 'ongoing' ? 'Ongoing' :
                              project.category === 'past' ? 'Past Project' :
                              project.category === 'ideas' ? 'Concept' : '';
            
            return (
              <div 
                key={project.id}
                className={`portfolio-item ${activeFilter === 'all' || activeFilter === project.category ? 'show' : 'hide'}`}
              >
                <div className="portfolio-card">
                  <span className={`status ${project.category}`}>{statusLabel}</span>
                  <div className="portfolio-image">
                    <img src={project.image} alt={project.title} />
                    <div className="portfolio-overlay">
                      <div className="overlay-content">
                        <p>{project.description}</p>
                        {(project.id === 'slsp' || 
                          project.id === 'ahah' || 
                          project.id === 'nno' ||
                          project.id === 'muzed' ||
                          project.id === 'gatherly' ||
                          project.id === 'rafteo' ||
                          project.id === 'bandbacon') && (
                          <button className="details-btn" onClick={() => onProjectClick(project.id)}>
                            More Details
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-info">
                    <h3>{project.title}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Render project details modals */}
      {selectedProject === 'slsp' && <SLSP onClose={onCloseProject} />}
      {selectedProject === 'ahah' && <AHAH onClose={onCloseProject} />}
      {selectedProject === 'nno' && <NNO onClose={onCloseProject} />}
      {selectedProject === 'muzed' && <Muzed onClose={onCloseProject} />}
      {selectedProject === 'gatherly' && <Gatherly onClose={onCloseProject} />}
      {selectedProject === 'rafteo' && <Rafteo onClose={onCloseProject} />}
      {selectedProject === 'bandbacon' && <BandBacon onClose={onCloseProject} />}
    </section>
  );
};

export default Portfolio;