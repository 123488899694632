import React, { useState } from 'react';

interface AHAHProps {
  onClose: () => void;
}

const AHAH: React.FC<AHAHProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>A Horse A Half (AHAH)</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2020</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status ideas">Concept</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">Adventure Group</span>
                </div>
                <div className="overview-item">
                  <span className="label">Frequency</span>
                  <span className="value">Multiple</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>A Horse A Half (AHAH) is a concept for an adventure group that organizes unusual and unique outdoor adventures. Currently we are just some close friends who participate in annual events like the Buena Vista 3 (BV3) to summit a 14er, bike from trailhead to river, and float on packrafts and SUPs - all in one day; a 5KM hike and BBQ every Cinco de Mayocalled Cinco de Hiko; and competing in the Hooligan race during FIBArk.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>Because, why not?</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">Lots of</span>
                    </div>
                    <span className="stat-label">Crazy Ideas</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">4</span>
                    </div>
                    <span className="stat-label">BV3s</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">2</span>
                    </div>
                    <span className="stat-label">Hooligan Races</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">Lots More</span>
                    </div>
                    <span className="stat-label">In the Works</span>
                  </div>
                </div>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2, 3, 4,].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/ahah/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/ahah/gallery${num}.jpg`}
                      alt={`AHAH Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default AHAH; 