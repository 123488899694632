import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { images } from '../assets';

const Hero: React.FC = () => {
  const el = useRef<HTMLSpanElement>(null);
  const typed = useRef<Typed | null>(null);

  useEffect(() => {
    if (el.current) {
      typed.current = new Typed(el.current, {
        strings: ['adventurer', 'explorer', 'creator', 'innovator'],
        typeSpeed: 100,
        backSpeed: 50,
        backDelay: 2000,
        loop: true
      });
    }

    return () => {
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, []);

  return (
    <section id="hero" style={{ backgroundImage: `url(${images.heroBackground})` }}>
      <div className="hero-container">
        <h1>Josh Manning</h1>
        <p><span ref={el}></span></p>
      </div>
    </section>
  );
};

export default Hero;