import React, { useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import './styles/main.css';

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [showContactAdventure, setShowContactAdventure] = useState(false);

  const handleCloseProject = () => {
    setSelectedProject(null);
  };

  const handlePortfolioProjectClick = (projectId: string) => {
    setSelectedProject(projectId);
  };

  const handleStartAdventure = () => {
    setShowContactAdventure(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <Header 
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        onProjectClick={handlePortfolioProjectClick}
        showContactAdventure={showContactAdventure}
        onStartAdventure={handleStartAdventure}
        onCloseAdventure={() => setShowContactAdventure(false)}
        activeProjects={[]}
      />
      <main className="main">
        <Hero />
        <About onProjectClick={handlePortfolioProjectClick} />
        <Portfolio 
          selectedProject={selectedProject}
          onCloseProject={handleCloseProject}
          onProjectClick={handlePortfolioProjectClick}
          onStartAdventure={handleStartAdventure}
        />
      </main>
      <Footer onStartAdventure={handleStartAdventure} />
    </div>
  );
};

export default App;