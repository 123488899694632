import React, { useState } from 'react';

interface SLSPProps {
  onClose: () => void;
}

const SLSP: React.FC<SLSPProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Sloan's Lake Search Party (SLSP)</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2023</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status ongoing">Ongoing</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">Event</span>
                </div>
                <div className="overview-item">
                  <span className="label">Frequency</span>
                  <span className="value">Annual</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>A unique marathon event where participants complete ten 2.62-mile loops around Sloan's Lake in Denver, Colorado - one loop every hour on the hour for 10 hours. While completing the full 26.2 miles, runners SEARCH for the mythical Goosifer (the Sloan's Lake Monster) and PARTY at basecamp with friends and family between loops.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>To create a unique event after realizing Sloan's Lake is nearly perfectly 1/10th of a marathon. I've run loop-based ultras and love the idea that you get to start and end at the same place so you actually get to hang with friends and family instead of seeing them once or maybe even twice if you're lucky. Inspired by Denver's very own Blucifer and legends of Big Foot.</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">26.2 </span>
                      <span className="unit">miles</span>
                    </div>
                    <span className="stat-label">Total Distance</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">10 </span>
                    </div>
                    <span className="stat-label">Loops</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">10</span>
                    </div>
                    <span className="stat-label">Hours</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">50</span>
                    </div>
                    <span className="stat-label">Participants</span>
                  </div>
                </div>
              </div>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://runsignup.com/Race/CO/Denver/SLSP" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-flag"></i>
                  Race Registration
                </a>
                <a href="https://runsignup.com/Race/SLSP/Page/Guide" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-file-text"></i>
                  Participant Guide
                </a>
                <a href="https://goosifer.net" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-chat-dots"></i>
                  Goosifer Forum
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2, 3, 4].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/slsp/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/slsp/gallery${num}.jpg`}
                      alt={`SLSP Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default SLSP;