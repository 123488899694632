import React, { useState } from 'react';

interface GatherlyProps {
  onClose: () => void;
}

const Gatherly: React.FC<GatherlyProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Gatherly</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2024</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status works">In the Works</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">Web App</span>
                </div>
                <div className="overview-item">
                  <span className="label">Platform</span>
                  <span className="value">Browser</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>Gatherly makes it easy to join events without and account and super simple to organize events without ever needing to remember yet another username and password. You can make an event, share it with friends, and add it to your calendar in just a few clicks. It's simple to use and respects your privacy.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>Born from the frustration of existing event platforms that require accounts, logins, and complex setup processes. Organizing events should be as simple as sharing a link, and attending should be as easy as clicking 'RSVP'. The goal is to reduce the barriers between people wanting to gather and actually making it happen.</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <p>Coming Soon!</p>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://gatherly.org" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-globe"></i>
                  Gatherly Website
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1,2,3].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/gatherly/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/gatherly/gallery${num}.jpg`}
                      alt={`Gatherly Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default Gatherly; 