import React, { useState } from 'react';

interface NNOProps {
  onClose: () => void;
}

const NNO: React.FC<NNOProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Nerd Nite Orlando</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2013</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status ongoing">Ongoing</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">Community Event</span>
                </div>
                <div className="overview-item">
                  <span className="label">Frequency</span>
                  <span className="value">Monthly</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>Nerd Nite is an evening of entertaining yet educational presentations. Speakers present for 20-25 minutes each on a fascinating subject of their choice, often accompanied by PowerPoint presentations and/or demonstrations. Meanwhile, the audience drinks along in an informal bar atmosphere.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>To create a space where experts (and people who think they're experts...nor not) and enthusiasts can share their passions with a receptive (and often inebriated) audience. The informal bar setting helps break down barriers between speakers and audience, fostering actual connections and genuine conversations about fascinating, and sometimes very obscure, topics.</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">100+</span>
                    </div>
                    <span className="stat-label">Total Events</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">300+</span>
                      <span className="unit">talks</span>
                    </div>
                    <span className="stat-label">Presentations</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">10+</span>
                      <span className="unit">years</span>
                    </div>
                    <span className="stat-label">Running</span>
                  </div>
                </div>
                <div className="stat-item">
                  <div className="stat-info">
                    <div className="stat-value">
                      <span className="number">1</span>
                      <span className="unit">very cool</span>
                    </div>
                    <span className="stat-label"><a href="https://nerdnite.com/book/" target="_blank" rel="noopener noreferrer">Book</a></span>
                  </div>
                </div>
              </div>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://orlando.nerdnite.com" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-globe"></i>
                  NNO Website
                </a>
                <a href="https://www.facebook.com/NerdNiteOrlando" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-facebook"></i>
                  NNO Facebook Page
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2, 3, 4].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/nno/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/nno/gallery${num}.jpg`}
                      alt={`NNO Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default NNO; 