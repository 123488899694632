import React, { useState } from 'react';

interface RafteoProps {
  onClose: () => void;
}

const Rafteo: React.FC<RafteoProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <>
      <div className="project-modal">
        <div className="modal-overlay" onClick={onClose}></div>
        
        <div className="modal-content">
          <div className="modal-fixed-header">
            <h2>Rafteo River Systems</h2>
          </div>

          <div className="modal-scrollable-content">
            <section className="project-overview">
              <h3>Overview</h3>
              <div className="overview-grid">
                <div className="overview-item">
                  <span className="label">Founded</span>
                  <span className="value">2024</span>
                </div>
                <div className="overview-item">
                  <span className="label">Status</span>
                  <span className="value">
                    <span className="status works">In the Works</span>
                  </span>
                </div>
                <div className="overview-item">
                  <span className="label">Type</span>
                  <span className="value">Product Design</span>
                </div>
                <div className="overview-item">
                  <span className="label">Industry</span>
                  <span className="value">Outdoor Gear</span>
                </div>
              </div>
            </section>

            <section className="project-description">
              <h3>Description</h3>
              <p>Rafteo River Systems designs and develops innovative technical gear for river sports that solve real problems faced by rafters, kayakers, and other river enthusiasts. Our focus is on creating equipment that enhances safety, improves functionality, and makes river adventures more enjoyable.</p>
            </section>

            <section className="project-motivation">
              <h3>Motivation</h3>
              <p>After years of rafting and rigging, I've encountered numerous challenges that could be solved with better-designed gear. Instead of continuing to use makeshift solutions or complaining about existing products, I decided to create my own solutions that address these common pain points in river sports, starting with a simple universal base and suite of accessories for camstraps.</p>
            </section>

            <section className="project-stats">
              <h3>Stats</h3>
              <p>Coming Soon!</p>
            </section>

            <section className="project-resources">
              <h3>Resources</h3>
              <div className="resources-list">
                <a href="https://rafteo.com" target="_blank" rel="noopener noreferrer" className="resource-link">
                  <i className="bi bi-globe"></i>
                  Rafteo Website
                </a>
              </div>
            </section>

            <section className="project-gallery">
              <h3>Gallery</h3>
              <div className="gallery-grid">
                {[1, 2, 3, 4].map((num) => (
                  <div 
                    key={num} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(`/img/projects/rafteo/gallery${num}.jpg`)}
                  >
                    <img 
                      src={`/img/projects/rafteo/gallery${num}.jpg`}
                      alt={`Rafteo Gallery ${num}`}
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="modal-fixed-footer">
            <button onClick={onClose} className="close-btn">
              Close
            </button>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-overlay"></div>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Gallery" />
          </div>
        </div>
      )}
    </>
  );
};

export default Rafteo; 